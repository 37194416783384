import React, { useRef, useState, useEffect } from "react";

import CustomInput, { INPUT_INVALID_STATUS, InvalidHint, VALIDATION_RULES } from "../CustomInput";
import Log from "@/utils/Log";
import {
  emailInvalidHint,
  passwordInvalidHint,
  passwordCheckInvalidHint,
  usernameInvalidHint,
} from "./invalidInputHints";
import { useAuth } from "@/hooks";
import LoadingButton from "../LoadingButton";
import LoadingMask from "../LoadingMask";

const RegisterUser = ({
  fixEmail,
  prefilledEmail,
}: {
  fixEmail?: string;
  prefilledEmail?: string;
}) => {
  const emailInputRef = useRef<HTMLInputElement>(null);
  const [email, setEmail] = useState<string>(fixEmail || prefilledEmail || "");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailInvalidHints, setEmailInvalidHints] = useState<React.ReactNode>(null);

  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [username, setUsername] = useState<string>("");
  const [usernameInvalidHints, setUsernameInvalidHints] = useState<React.ReactNode>(null);

  const passwordInputRef = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState<string>("");
  const [pswHint, setPswHint] = useState<React.ReactNode>(null);
  const [pswInvalidHints, setPswInvalidHints] = useState<React.ReactNode>(null);

  const passwordInputRefCheck = useRef<HTMLInputElement>(null);
  const [passwordCheck, setPasswordCheck] = useState<string>("");
  const [pswCheckInvalidHints, setPswCheckInvalidHints] = useState<React.ReactNode>(null);

  const auth = useAuth();

  function getEmailInvalidHint(email: string) {
    if (!email) {
      return emailInvalidHint(INPUT_INVALID_STATUS.EMPTY);
    }
    if (!VALIDATION_RULES.EMAIL_REGEX_PATTERN.test(email)) {
      return emailInvalidHint(INPUT_INVALID_STATUS.INVALID);
    }
    return null;
  }

  function handleEmailInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const email = e?.target?.value || "";
    setEmail(email);
  }

  function handleEmailFinished() {
    if (!email) return;
    usernameInputRef?.current?.focus();
  }

  function getUsernameInvalidHints(username: string) {
    if (!username) {
      return usernameInvalidHint(INPUT_INVALID_STATUS.EMPTY);
    }
    if (!VALIDATION_RULES.USERNAME_REGEX_PATTERN.test(username)) {
      return usernameInvalidHint(INPUT_INVALID_STATUS.EXCEEDED);
    }
    return null;
  }

  function handleUsernameInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const username = e?.target?.value || "";
    setUsername(username);
  }

  function handleUsernameFinished() {
    if (!username) return;
    passwordInputRef?.current?.focus();
  }

  function handlePasswordInputFocus() {
    setPswHint(
      <InvalidHint
        content="At least 8 characters with 1 lowercase character, 1 uppercase character, and 1 number. No spaces."
        className="is-black "
      />
    );
  }

  function handlePasswordFinished() {
    setPswInvalidHints(null);
    passwordInputRefCheck?.current?.focus();
  }

  function handlePasswordInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const password = e?.target?.value.replace(/\s/g, "") || "";
    setPassword(password);
  }

  function handlePasswordInputChangeCheck(e: React.ChangeEvent<HTMLInputElement>) {
    const passwordCheck = e?.target?.value.replace(/\s/g, "") || "";
    setPasswordCheck(passwordCheck);
  }

  function handlePasswordFinishedCheck(e?: React.FormEvent) {
    e?.preventDefault();
    setEmailInvalidHints(null);
    setUsernameInvalidHints(null);
    setPswInvalidHints(null);
    setPswCheckInvalidHints(null);
    const trimEmail = email.toLowerCase().trim();
    console.log("trim: ", trimEmail);
    const trimUsername = username.trim();
    console.log("trim: ", trimUsername);
    const trimPassword = password.trim();
    const trimPasswordCheck = passwordCheck.trim();
    setEmail(trimEmail);
    setUsername(trimUsername);
    setPassword(trimPassword);
    setPasswordCheck(trimPasswordCheck);

    const emailInvalidHints = getEmailInvalidHint(trimEmail);
    setEmailInvalidHints(emailInvalidHints);
    if (emailInvalidHints) {
      return;
    }

    const usernameInvalidHints = getUsernameInvalidHints(trimUsername);
    setUsernameInvalidHints(usernameInvalidHints);
    if (usernameInvalidHints) {
      return;
    }

    const pswCheckRes = passwordInvalidHint(trimPassword);
    setPswInvalidHints(pswCheckRes);
    if (pswCheckRes) {
      return;
    }
    const pswDoubleCheckRes = passwordCheckInvalidHint(trimPasswordCheck, trimPassword);
    setPswCheckInvalidHints(pswDoubleCheckRes);
    if (pswDoubleCheckRes) {
      return;
    }
    Log.debug("register user info: ", trimEmail, trimUsername, trimPassword);
    setLoading(true);
    auth
      .registerUserWithPassword(trimEmail, trimUsername, trimPassword)
      .then(({ emailHasRegistered }) => {
        if (emailHasRegistered) {
          setEmailInvalidHints(
            <InvalidHint content="This email address has already been registered. Please sign in." />
          );
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    emailInputRef?.current?.focus();
  }, []);

  return (
    <>
      <LoadingMask isLoading={loading} />
      <form>
        <CustomInput
          disabled={!!fixEmail}
          inputRef={emailInputRef}
          type="email"
          value={email}
          autoComplete="off"
          onChange={handleEmailInputChange}
          onEnter={handleEmailFinished}
          invalidHintContent={emailInvalidHints}
        />
        <CustomInput
          inputRef={usernameInputRef}
          type="username"
          value={username}
          autoComplete="off"
          onChange={handleUsernameInputChange}
          onEnter={handleUsernameFinished}
          invalidHintContent={usernameInvalidHints}
        />
        <CustomInput
          inputRef={passwordInputRef}
          type="password"
          value={password}
          autoComplete="off"
          onChange={handlePasswordInputChange}
          onFocus={handlePasswordInputFocus}
          onBlur={() => setPswHint(null)}
          onEnter={handlePasswordFinished}
          hintContent={pswInvalidHints ? null : pswHint}
          invalidHintContent={pswInvalidHints}
        />
        <CustomInput
          inputRef={passwordInputRefCheck}
          type="password"
          placeholder="Confirm password"
          value={passwordCheck}
          autoComplete="off"
          onChange={handlePasswordInputChangeCheck}
          onEnter={handlePasswordFinishedCheck}
          invalidHintContent={pswCheckInvalidHints}
        />
        <LoadingButton
          className="button-primary-360"
          onClick={handlePasswordFinishedCheck}
          isLoading={loading}
        >
          Continue
        </LoadingButton>
      </form>
    </>
  );
};

export default RegisterUser;
